import React, { useState } from "react";
import Sidebar from "./components/Sidebar";
import Content from "./components/Content";
import Header from "./components/Header";

const App = () => {
    const [enabledCubes, setEnabledCubes] = useState([]); // Cube 상태 관리
    const [cubeColors, setCubeColors] = useState({}); // Cube 색상 관리

    const toggleCube = (id, enabled) => {
        setEnabledCubes(enabled ? [id] : []); // 단일 Cube 활성화/비활성화
    };

    const changeCubeColor = (id, hexCode) => {
        setCubeColors((prev) => ({ ...prev, [id]: hexCode }));
    };

    return (
        <div>
            <Header />
            <div style={{ display: "flex" }}>
                <Sidebar 
                    enabledCubes={enabledCubes} 
                    toggleCube={toggleCube} 
                    changeCubeColor={changeCubeColor} 
                />
                <Content enabledCubes={enabledCubes} cubeColors={cubeColors} />
            </div>
        </div>
    );
};

export default App;

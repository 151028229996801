import React from "react";
import "../styles/sidebar.css";

const Sidebar = ({ enabledCubes, toggleCube, changeCubeColor }) => {
    const options = [
        { id: 1, color: "#ffffff" },
        { id: 2, color: "#ffffff"},
        { id: 3, color: "#ffffff" },
        { id: 4, color: "#ffffff"},
    ];

    return (
        <div className="sidebar">
            <ul>
                {options.map((option) => (
                    <li key={option.id}>
                        {option.id} &nbsp;
                        <input
                            type="checkbox"
                            checked={enabledCubes.includes(option.id)}
                            onChange={(e) => toggleCube(option.id, e.target.checked)}
                        />
                        &nbsp;
                        <input
                            type="color"
                            defaultValue={option.color}
                            onChange={(e) => changeCubeColor(option.id, e.target.value)}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Sidebar;
